<template>
<div>


  <div class="print-intro row">
    <div class="col-md-6 col-2-3">
      <strong > Ημερομηνία: </strong>{{ getDate() }}<br>
      <span ><strong>Διατροφολόγος:</strong> {{user.firstName + ' ' + user.lastName}} </span>
    </div>
    <div class="col-md-6 col-1-3 text-right" >
      <img :src="user.logo ? user.logo : 'https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-icon-printing.png'" width="150"
           style="max-height:75px;width:auto;max-width:150px;" class="pr-3"/>
    </div>


  </div>
  <div v-if="title" class="col-md-12">
    <div class="article-title" v-html="title" style="border-radius:8px;"></div>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default{
  props: ['title'],
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
  },
}
</script>
