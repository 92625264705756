import printJS from "print-js";

export default{

  computed:{
    isSafari(){
      return navigator.vendor === "Apple Computer, Inc.";
    }
  },
  data(){
    return{
      printLoading: false,
      email:{
        to: '',
        subject: null,
        content: ''
      },
      printTypes:{
        print: 'print',
        save: 'save',
        email: 'email'
      },
      athlisisDefaultPrintStyles: `<style>table td,table tr{max-width:130px;min-width:130px}.print-intro,.use-exercise-wrapper{padding:10px}body{font-family:"Open Sans",sans-serif;margin-bottom:25mm}@media print{*{-webkit-print-color-adjust:exact!important;color-adjust:exact!important}body,html{width:210mm;height:297mm;max-height:99%}@page{size:A4;padding:0!important;margin:0;max-height:99%}table{width:100%!important}}table tr{font-size:12px!important}.modal-dialog{width:100%!important}button{display:none!important}.row-header{font-weight:700}table td{vertical-align:top;padding:3px 2px}tr:nth-child(2n){background-color:#f3f3f3}th{min-width:80px}table{min-width:100%}.pagebreak{page-break-before:always}.row{display:block}.col-md-4{display:inline-block;height:350px;width:50%}.col-md-6{margin:10px}.text-right{text-align:right}.col-2-3{width:66%}.col-1-3{width:33%}.title-exercise{border-bottom:1px solid #000;width:30%;margin-bottom:30px}.athlisis-logo{position:fixed;bottom:15;right:15;opacity:.3}.print-intro{display:flex}.apexcharts-xaxis line,.apexcharts-ycrosshairs{display:none}#program-analysis-print{margin-bottom:0!important;padding-bottom:0!important;overflow:hidden}</style>`,
      printDefaultStyles: '<style>*{font-family: "Open Sans", sans-serif;}p,li{font-size:0.8em}' +
          '@page{margin:.2in;}.print-intro{display:flex!important;padding-bottom:0px;align-items:center;}.article-category{display:none}' +
          '.article-title,.article-footer{background-color:#E5EAFA!important;padding:20px;text-align:center}img{max-width:100%!important;max-height:450px;}' +
          '.col-md-2{width:20%;display:inline-block;margin-top:0.1em}.name{margin-top:0.5em}.resize-triggers{display:none}' +
          '.date-muted{opacity:0.7;font-size:0.8em;margin-bottom:0.6em;margin-top:1em;}' +
          '.value-number{margin-top:.2em;font-weight:bold}' +
          '.print-events tr > td{max-width:25%}.print-events td{padding:10px;}.print-events table{margin-top:20px;}'+
          '.event-item{break-inside: avoid-page;}.row{display:block;}.col-3{display:inline-block;width:25%}.col-9{display:inline-block;width:75%}' +
          '</style>'
    }
  },

  methods:{

    print(div, type = this.printTypes.print, title = null, category = 'diet-plan', styleOptions = null){

      let printableTitle = title || this.title;
      this.printLoading = true;
      let html = '<html><head><title>' + printableTitle  +'</title>';


      if(styleOptions && styleOptions.specificStyles){
        html+= `<style>${styleOptions.styles}</style>`;
      }else{

        html += this.printDefaultStyles;
        html += this.athlisisDefaultPrintStyles;
      }


      html += '</head><body>';
      html += div;
      html += '</body></html>';
      //
      // if(category === 'fat-meter'){
      //   let myWindow=window.open('','','width=700,height=1400');
      //   myWindow.document.write(html);
      //   myWindow.document.close();
      //   myWindow.focus();
      //   myWindow.print();
      //   // myWindow.close();
      //
      //   return;
      // }
      
      this.$axios.post('/general/print-pdf', {html: html, isLandscape: false, send_to_customer_email: type === this.printTypes.email,
            email: this.email, category: category},
          {'responseType': 'blob'}).then(async res => {

        const url = URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));

        if(type === this.printTypes.save){
          let link = document.createElement('a');
          link.href = url;
          link.download= printableTitle + ".pdf";
          link.click();
        }

        if(type === this.printTypes.print){
          // safari sepcfiic behavior.
          if(this.isSafari){
            try{
              let newWin = window.open(url, "","toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=900");
              newWin.focus();
              setTimeout( function (){ newWin.print(); }, 500);
            }catch(e){
              this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                text: 'Παρακαλώ ακολουθήστε τις οδηγίες που υπάρχουν για σωστή εκτύπωση στο Safari.'});
            }

          }else{
            printJS(url);
          }
        }
        if(type === this.printTypes.email){
          this.emailSent = true;
        }

      }).catch(e=>{
        if(e && e.response && e.response.status === 429){
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.rateLimitEmail')});
          return;
        }
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});

      }).finally(()=>{
        this.printLoading=false;
      });
    }

  }
}
